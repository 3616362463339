import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Support = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Support</h1>
    <p>
        Email aaron@archivesapp.com for any support requests.
    </p>
  </Layout>
)

export default Support